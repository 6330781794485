import React from "react";
import helper from "utils/helper";
import "./work.css";
const WorkDateTime = ({ value }) => {
  return (
    <div>
      <div className="container-work-comp">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "5px 10px 5px 10px",
          }}
        >
          <img
            src={
              value && value.image
                ? value.image
                : require("assets/images/work/datetime.png")
            }
            height={40}
            alt={"datetime"}
          />
        </div>
        <div style={{ flex: 1, display: "flex" }}>
          <div style={{ flex: 1, textAlignLast: "start" }}>
            <div
              style={{
                fontWeight: 500,
                color: value.date ? "#121f43" : "#ff316f",
                fontSize: 14,
              }}
            >
              {"ช่วงวันให้เข้ารับสินค้า*"}
            </div>
            <div style={{ fontSize: 13, opacity: value.date ? 1 : 0.3 }}>
              {value.date
                ? helper.momentTwoDate(
                    value.date.startDate,
                    value.date.endDate,
                    "short",
                    false
                  )
                : "โปรดระบุช่วงวัน"}
            </div>
          </div>
        </div>
        <div style={{ flex: 1, display: "flex" }}>
          <div style={{ flex: 1, textAlignLast: "start" }}>
            <div
              style={{
                fontWeight: 500,
                color: value.time ? "#121f43" : "#ff316f",
                fontSize: 14,
              }}
            >
              {"ช่วงเวลาที่สะดวก*"}
            </div>
            <div style={{ fontSize: 13, opacity: value.time ? 1 : 0.3 }}>
              {value?.time?.time_start + " - " + value?.time?.time_end}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkDateTime;
