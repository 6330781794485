import React, { useState, useEffect } from "react";
import { getAllStlStandardSize } from "api/masterData/stlStandard";
import "./work.css";
const WorkProduct = ({ value = null, workType = "ftl" }) => {
  const [stlSize, setStlSize] = useState([]);
  console.log("workType", workType, value, stlSize);
  useEffect(() => {
    if (workType === "stl") {
      getStl();
    }
  }, [workType]);
  const getStl = async () => {
    let stl = await getAllStlStandardSize();
    setStlSize(stl.data.data.results);
  };
  const getWeight = () => {
    let weight = 0;
    value.forEach((e) => {
      if (e.product_weight) weight += parseInt(e.product_weight);
    });
    return weight === 0 || isNaN(weight) ? "-" : weight;
  };
  const getVolume = () => {
    let volume = 0;
    value.forEach((e) => {
      if (e.product_volume) volume += parseFloat(e.product_volume);
    });
    return volume === 0 || isNaN(volume) ? "-" : volume.toFixed(2);
  };
  const getSize = () => {
    let text = "";
    if (value && value.length > 0) {
      let s = stlSize.filter((e) => e.id === value[0].product_id);
      if (s.length > 0) {
        text = s[0].name ? s[0].name + " " : "";
      }
      return text;
    }
  };

  return (
    <div>
      <div className="container-work-comp">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "5px 10px 5px 10px",
          }}
        >
          <img
            src={
              value && value.image
                ? value.image
                : require("assets/images/work/product.png")
            }
            height={40}
            alt={"product"}
          />
        </div>
        <div style={{ flex: 1, textAlignLast: "start" }}>
          <div
            style={{
              fontWeight: 500,
              color:
                value[0].name && value[0].name !== "" ? "#121f43" : "#ff316f",
            }}
          >
            {"รายละเอียดสินค้าที่จัดส่ง*"}
          </div>
          <div
            style={{
              fontSize: 13,
              opacity: value[0].name && value[0].name !== "" ? 1 : 0.3,
            }}
          >
            {value[0].name && value[0].name !== ""
              ? value.map((ele, index) => {
                  return (
                    <label key={"name_product" + index}>{ele.name} </label>
                  );
                })
              : "ระบุประเภทสินค้า ชนิดสินค้า และน้ำหนัก"}
            {value[0].product_volume !== "" ? (
              <div>
                {(workType === "stl" && value[0].product_size
                  ? getSize()
                  : "") +
                  "น้ำหนักรวม " +
                  getWeight() +
                  " ก.ก. ปริมาตรรวม " +
                  getVolume() +
                  " ลบ.ม."}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkProduct;
