// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listTitle {
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}
.pageTitle {
    padding: 5px;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
  }
  .ph10 {
    padding: 10px 0;
  }
  .f16{
    font-size: 16px;
  }
  
  .textUnderline{
    color: #0D19A3;
    text-decoration-line: underline;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/pages/ProductAndService/ProductAndService.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,gBAAgB;AAClB;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,+BAA+B;IAC/B,eAAe;EACjB","sourcesContent":[".listTitle {\n  text-align: center;\n  color: #fff;\n  font-size: 20px;\n  font-weight: 500;\n}\n.pageTitle {\n    padding: 5px;\n    text-align: center;\n    font-size: 22px;\n    font-weight: 500;\n  }\n  .ph10 {\n    padding: 10px 0;\n  }\n  .f16{\n    font-size: 16px;\n  }\n  \n  .textUnderline{\n    color: #0D19A3;\n    text-decoration-line: underline;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
