// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .modal-select-map .MuiInputBase-root {
  padding: 10px 0px 0px 40px;
}
.modal-select-map .MuiFormLabel-root {
  padding: 10px 0px 0px 40px;
} */

.modal-select-truck .modal-select-truck-options {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.modal-select-truck .modal-select-truck-options:hover {
  background-color: #121f43;
  color: white;
}
.modal-select-truck .modal-select-truck-options-select {
  background-color: rgb(255, 41, 41);
}
.swal2-container {
  z-index: 20000;
}
`, "",{"version":3,"sources":["webpack://./src/components/work/modalSelectMap.css"],"names":[],"mappings":"AAAA;;;;;GAKG;;AAEH;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,kCAAkC;AACpC;AACA;EACE,cAAc;AAChB","sourcesContent":["/* .modal-select-map .MuiInputBase-root {\n  padding: 10px 0px 0px 40px;\n}\n.modal-select-map .MuiFormLabel-root {\n  padding: 10px 0px 0px 40px;\n} */\n\n.modal-select-truck .modal-select-truck-options {\n  display: flex;\n  align-items: center;\n  text-align: center;\n  padding: 10px;\n  cursor: pointer;\n}\n.modal-select-truck .modal-select-truck-options:hover {\n  background-color: #121f43;\n  color: white;\n}\n.modal-select-truck .modal-select-truck-options-select {\n  background-color: rgb(255, 41, 41);\n}\n.swal2-container {\n  z-index: 20000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
