import React from "react";
import { Button } from "@mui/material";
const ButtonCustom = ({
  name = "",
  onClick = () => {},
  color = "secondary",
  fullWidth = false,
}) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      color={color}
      style={{
        height: "50px",
        borderRadius: "10px",
        fontSize: "16px",
        width: fullWidth ? "100%" : "auto",
      }}
    >
      {name}
    </Button>
  );
};

export default ButtonCustom;
