import React, { useState, useEffect } from "react";
import { work } from "../../assets/mock/mockWork";
import WorkCard from "../../components/card/WorkCard";
import API from "../../api/work/work";
import { useParams, useNavigate } from "react-router-dom";
import backArrow from "assets/images/workCard/back-arrow.svg";
const api = API.create();
const ListWork = () => {
  const navigate = useNavigate();
  const { truck_id, zone_id } = useParams();
  const [filter, setFilter] = useState({
    truck_type_id: truck_id,
    zone: zone_id,
  });
  const [workData, setWorkData] = useState([]);

  useEffect(() => {
    getAllWorkRegularData(filter);
  }, []);

  const getAllWorkRegularData = async (_filter) => {
    await api.getAllWorkRegular(_filter).then((res) => {
      if (res.data.code === 1000) {
        setWorkData(res.data.results.data);
      }
    });
  };

  return (
    <div className="container">
      <div
        style={{
          paddingTop: "20px",
          paddingLeft: "20px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div onClick={() => navigate(-1)}>
          <img src={backArrow} width={25} />
        </div>
      </div>
      <div
        style={{
          position: "sticky",
          top: 0,
          left: 0,
          backgroundColor: "white",
          width: "105%",
          zIndex: 20,
        }}
      >
        <div className="headTitle" style={{ paddingTop: 0 }}>งานประจำ</div>
      </div>

      {workData?.map((val, i) => (
        <WorkCard key={i} data={val} />
      ))}
    </div>
  );
};

export default ListWork;
