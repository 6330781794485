import React from "react";
import BackButton from "../../components/button/BackButton";
import ButtonCustom from "../../components/button/ButtonCustom";
import "./ProductAndService.css"
const Fuel = () => {
  return (
    <div className="container">
    <BackButton />
    <div className="pageTitle">บัตรเติมน้ำมันบางจาก</div>
    <div style={{padding:"10px"}}>
      <img
        src={require("../../assets/images/productAndService/bangchak.png")}
        alt=""
        width={"100%"}
        resizemode="contain"
      />
    </div>
    <div style={{width:'80%'}}>
      <ButtonCustom
        name="ทักไลน์ติดต่อซื้อบัตรน้ำมันบางจาก"
        onClick={() => {
          window.open(
            "https://page.line.me/smv4552s?oat_content=url&openQrModal=true"
          );
        }}
        fullWidth={true}
      />
    </div>
  </div>
  );
};

export default Fuel;
