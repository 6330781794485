import React from "react";
import { Button } from "@mui/material";
import "./card.css";
import pinBlue from "../../assets/images/workCard/pinBlue.svg";
import pinRed from "../../assets/images/workCard/pinRed.svg";
import logoLine from "../../assets/images/workCard/logoLine.webp";
import { useNavigate, useParams } from "react-router-dom";
import helper from "utils/helper";
const WorkCard = ({ data }) => {
  const { truck_id, zone_id } = useParams();
  const navigate = useNavigate();
  console.log(data);
  return (
    <div className="cardContainer">
      <div className="headBar">
        <div className="headTitileCard">{data?.work_regular_code}</div>
        <div className="rightTitleCard">{data?.work_regular_name}</div>
      </div>
      <div className="bottomCard">
        <div style={{ padding: "0 20px" }}>
          <div className="section">
            <div style={{ flex: 0.6, borderRight: "1px solid lightgray" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ padding: "10px" }}>
                  <img
                    src={helper.addhtpps(
                      helper.pathImage() + data?.truck_type?.image
                    )}
                    alt="truck image"
                    width={150}
                  />
                </div>
                <div style={{ fontSize: "14px" }}>{data?.truck_type?.name}</div>
              </div>
            </div>
            <div style={{ flex: 0.4, paddingLeft: "15px" }}>
              <div style={{ paddingBottom: "5px", fontSize: "14px" }}>
                {data.product_type.name}
              </div>
              <div style={{ fontWeight: 300, fontSize: "12px" }}>
                {data.product_name}
              </div>
            </div>
          </div>
          <div className="section">
            <div
              style={{
                flex: 1,
                padding: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <div style={{ fontWeight: 300, fontSize: "12px",display:'flex' }}>
                  <img src={pinBlue} width={20} />
                  {Array.isArray(data.workRegularOrigin) &&
                  data.workRegularOrigin.length > 1
                    ? "มีหลายจุดรับ"
                    : data.workRegularOrigin[0].origin_province.name}
                </div>
                <div style={{ fontWeight: 300, fontSize: "12px",display:'flex' }}>
                  <img src={pinRed} width={20} />
                  {Array.isArray(data.workRegularDestination) &&
                  data.workRegularDestination.length > 1
                    ? "มีหลายจุดส่ง"
                    : data.workRegularDestination[0].destination_province.name}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "10px",
            }}
          >
            <div style={{ flex: 0.55 }}>
              <Button
                variant="contained"
                onClick={() => window.open("https://lin.ee/G9URMOgj")}
                style={{ backgroundColor: "#06c755", borderRadius: "10px" }}
              >
                <img src={logoLine} alt="logo line" width={22} />
                <div style={{ fontSize: "12px" }}>สมัคร/สอบถาม</div>
              </Button>
            </div>
            <div style={{ flex: 0.45 }}>
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    `/work-card/${truck_id}/zone/${zone_id}/detail/${data.id}`
                  );
                }}
                color={"secondary"}
                style={{ borderRadius: "10px" }}
                fullWidth={true}
              >
                <div style={{ fontSize: "12px" }}>ดูรายละเอียด</div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkCard;
