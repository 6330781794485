import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "./modalSelectMap.css";
import { BorderColor } from "@mui/icons-material";
// const google = window.google;
const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  maxWidth: 400,
  flexDirection: "column",
  overflow: "scroll",
  maxHeight: "90vmin",
  bgcolor: "background.paper",
  borderRadius: 5,
  color: "#8ca3ba",
  boxShadow: 24,
};
const ModalWork = (props) => {
  const { value, close, open, onChange, confirm, minHeight, width } = props;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  return (
    <div>
      <Modal open={open} >
        <Box
          sx={{
            ...style,
            minHeight: minHeight ? "80vh" : "",
            minWidth: width ?? isMobile ? 350 : 400,
            maxWidth: width ?? isMobile ? 350 : 400,
            
          }}
        >
          {props.enableClose ? (
            <div
              style={{
                float: "right",
                cursor: "pointer",
                textAlign: "end",
                padding: "10px 10px 0px 0px",
                marginBottom: -5,
              }}
              onClick={() => {
                close();
              }}
            >
              <CloseIcon sx={{ color: "#8ca3ba" }} />
            </div>
          ) : null}
          <div>{props.children}</div>
          {props.showBottom ? (
            <div
              style={{
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                padding: 20,
                borderRadius: 20,
                textAlign: "center",
              }}
            >
              {props.bottombar}
            </div>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
};

export default ModalWork;
