import React, { useEffect, useState } from "react";
import "./workPublicDetail.css";
import Loading from "components/loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { getTruckType as getAllTruckType } from "api/masterData/truckType";
import { getAllWorkPublic } from "api/public/workPublic";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import Logo from "assets/images/work/logoWemoveWhite.svg";
import line_icon from "assets/images/workCard/logoLine.webp";
import helper from "utils/helper";
import { Button } from "@mui/material";
const PublicWorkCard = () => {
  const { truck_id } = useParams();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loading, setLoading] = useState(false);
  const [truckMaster, setTruckMaster] = useState([]);
  const [allWork, setAllWork] = useState(null);
  const [page, setPage] = useState(1);
  const [totalWork, setTotalWork] = useState(0);
  const [isBottom, setIsBottom] = useState(false);

  const [maxScrollTop, setMaxScrollTop] = useState(
    document.documentElement.scrollHeight -
      document.documentElement.clientHeight
  );
  const [product, setProduct] = useState({
    product: "",
    weight: 0,
    volume: 0,
    size: "",
  });
  const [loadScroll, setLoadScroll] = useState(false);
  const [isAll, setIsAll] = useState(false);

  useEffect(() => {
    getTruckMaster();
    getAllWork(page);
  }, []);

  useEffect(() => {
    console.log("truckMaster", truckMaster);
    console.log("allWork", allWork);
  }, [allWork, truckMaster]);
  const getTruckMaster = async () => {
    const param = {
      sort_field: ["order_number"],
      sort_order: [1],
    };
    await getAllTruckType(param)
      .then((res) => {
        console.log("all truck type", res);
        if (res.data.code === 1000) {
          setTruckMaster(res.data.data.results);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getAllWork = async (_page) => {
    console.log("do count data");
    setLoading(true);
    const param = {
      type_of_list: "detail",
      truck_type_id: truck_id,
      page: _page,
      per_page: 5,
    };

    await getAllWorkPublic(param)
      .then((response) => {
        if (response.data.code === 1000) {
          let job = [];
          console.log("response", response);
          if (allWork?.length > 0) {
            job = allWork.concat(response.data.data);
          } else {
            job = response.data.data;
          }
          setAllWork(job);
          setTotalWork(response.data.total);
          setLoading(false);
        }
      })
      .catch((error) => console.log("countres", error));
  };

  const productDetail = (detail) => {
    let text = "";
    let unload_place = detail.workLocations.filter(
      (e) => e.location_type === 0
    );
    let prod = 0;
    let weight = 0;
    let volume = 0;
    let size;
    unload_place.forEach((e, i) => {
      e.workLocationProducts.forEach((ele, index) => {
        if (i === 0) {
          text += ele.product.name;
        } else {
          prod++;
        }
        weight += parseFloat(ele.weight);
        volume += parseFloat(ele.volume);
      });
    });
    if (prod > 0) {
      text += "และอีก " + prod + " ประเภท";
    }
    return {
      product: text,
      weight: isNaN(weight) ? "-" : weight,
      volume: isNaN(volume) ? "-" : volume,
      size: size,
    };
    // return text;
  };

  const getService = (detail) => {
    const serv = null;
    let text = "";
    if (detail.has_document_return) {
      text += "+เอกสารนำกลับ ";
    }
    if (detail.has_labor) {
      if (detail.labor_load_amount > 0) {
        text += "+คนงานยกสินค้าขึ้น " + detail.labor_load_amount + " คน ";
      }
      if (detail.labor_unload_amount > 0) {
        text += "+คนงานยกสินค้าลง " + detail.labor_unload_amount + " คน ";
      }
    }
    if (text !== "") {
      return <div>{text}</div>;
    }
    return serv;
  };

  const getRoute = (detail) => {
    let rotueList = [];
    const sort = helper.sortArray(detail.workLocations);
    // console.log('sort', sort);
    let other = 0;
    detail.workLocations = sort;
    detail.workLocations.forEach((ele, index) => {
      if (index > 1) {
        other += 1;
      } else {
        rotueList.push(
          <div
            key={"route" + index}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div>
              <img
                src={
                  ele.location_type === 0
                    ? require("assets/images/work/pickup_pin.png")
                    : require("assets/images/work/send_pin.png")
                }
                alt="pin1"
                height={"20px"}
                resizeMode="contain"
              />
            </div>

            <div style={{ marginLeft: 5 }}>
              {ele.district} {ele.province}
            </div>
          </div>
        );
      }
    });
    if (other > 0) {
      rotueList.push(
        <div key={"route" + detail.workLocations.length}>
          <div>+ {other} จุด</div>
        </div>
      );
    }
    return (
      <div>
        {detail.workLocations &&
          detail.workLocations[0].location_type === 0 && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <img
                  src={require("assets/images/work/pickup_pin.png")}
                  alt="pin2"
                  width={"20px"}
                  resizeMode="contain"
                />
              </div>

              <div style={{ marginLeft: 5 }}>
                {detail.workLocations[0].district}{" "}
                {detail.workLocations[0].province}
              </div>
            </div>
          )}
        {detail.workLocations.length > 2 && (
          <div>
            <div>+ {detail.workLocations.length - 2} จุด</div>
          </div>
        )}
        {detail.workLocations &&
          !(
            detail.workLocations[detail.workLocations.length - 1]
              .location_type === 0
          ) && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <img
                  src={require("assets/images/work/send_pin.png")}
                  alt="pin3"
                  width={"20px"}
                  resizeMode="contain"
                />
              </div>

              <div style={{ marginLeft: 5 }}>
                {detail.workLocations[detail.workLocations.length - 1].district}{" "}
                {detail.workLocations[detail.workLocations.length - 1].province}
              </div>
            </div>
          )}
      </div>
    );
  };

  const handleClick = (wid, type) => {
    const appUrl = `wemoveapp://work/${wid}`;
    window.location.href = appUrl;
    setTimeout(() => {
      redirectToStore();
    }, 2000);
  };

  const redirectToStore = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      isMobile
        ? (window.location.href = "http://onelink.to/3dpe9h")
        : (window.location.href =
            "https://apps.apple.com/th/app/wemovedrive/id1602456992");
    } else if (/android/i.test(userAgent)) {
      isMobile
        ? (window.location.href = "http://onelink.to/3dpe9h")
        : (window.location.href =
            "https://play.google.com/store/apps/details?id=co.th.wemove.app&pli=1");
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=co.th.wemove.app&pli=1";
    }
  };
  const loadMore = () => {
    getAllWork(page + 1);
    setPage(page + 1);
  };
  return (
    <div className={!isMobile ? "containerForWebPW" : "containerPW"}>
      {loading && <Loading />}
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          fontSize: "20px",
          fontWeight: 500,
          width: "100%",
          padding: "10px",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIosRoundedIcon />
        ย้อนกลับ
      </div>
      {allWork &&
        allWork?.map((val, i) => {
          return (
            <div style={{ width: "100%", padding: "10px 20px" }}>
              <div key={i} className="cardWork">
                <div
                  style={{
                    backgroundColor:
                      val?.service_id === 1 ? "#EC1F26" : "#191347",
                    height: "55px",
                    width: "100%",
                    borderRadius: "15px 15px 0 0",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Logo}
                      alt="logo"
                      height={20}
                      resizeMode="contain"
                    />
                  </div>

                  <div style={{ paddingLeft: "20px" }}>
                    <div style={{ color: "white", fontWeight: 500 }}>
                      {val?.work_code}
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        color: "white",
                        fontWeight: 500,
                      }}
                    >
                      {val?.work_name}
                    </div>
                  </div>
                </div>
                <div
                  className="part1"
                  style={{
                    display: "flex",
                    flex: 1,
                    borderBottom: `2px solid rgba(140, 163, 186, 0.2)`,
                  }}
                >
                  <div
                    style={{
                      flex: 0.5,
                      borderRight: `2px solid rgba(140, 163, 186, 0.2)`,
                      padding: "5px",
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        src={helper.addhtpps(
                          helper.pathImage() + val?.truck_type?.image
                        )}
                        alt=""
                        // width={215}
                        height={50}
                        resizeMode="contain"
                      />
                    </div>

                    <div style={{ fontWeight: 500 }}>
                      {val?.truck_type?.name}
                    </div>
                    <div
                      style={{ fontSize: "12px" }}
                    >{`${val?.truck_type?.width} x ${val?.truck_type?.length} x ${val?.truck_type?.height} ม. ${val?.truck_type?.weight} กก. `}</div>
                  </div>
                  <div style={{ flex: 0.5, padding: "5px" }}>
                    <div style={{ fontWeight: 500 }}>
                      {productDetail(val).product}
                    </div>
                    <div>น้ำหนักรวม {productDetail(val).weight} ก.ก.</div>
                    <div>ปริมาตรรวม {productDetail(val).volume} ลบ.ม.</div>
                  </div>
                </div>
                <div
                  className="part2"
                  style={{
                    display: "flex",
                    flex: 1,
                    borderBottom: getService(val)
                      ? `2px solid rgba(140, 163, 186, 0.2)`
                      : "",
                  }}
                >
                  {getService(val)}
                </div>
                <div
                  className="part3"
                  style={{
                    display: "flex",
                    flex: 1,
                    borderBottom: `2px solid rgba(140, 163, 186, 0.2)`,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 0.65,
                      padding: "5px",
                      borderRight: `2px solid rgba(140, 163, 186, 0.2)`,
                    }}
                  >
                    {getRoute(val)}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 0.35,
                      padding: "5px",
                      flexDirection: "column",
                    }}
                  >
                    <div>ระยะทาง</div>
                    <div style={{ fontSize: "24px", fontWeight: 500 }}>
                      {val?.total_distance ?? "-"} กม.
                    </div>
                  </div>
                </div>
                <div
                  className="part4"
                  style={{
                    display: "flex",
                    flex: 1,
                    borderBottom: `2px solid rgba(140, 163, 186, 0.2)`,
                    padding: "5px",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div>ช่วงวันเวลาที่สะดวกให้เข้ารับสินค้า</div>
                  <div style={{ fontSize: "24px", fontWeight: 500 }}>
                    {helper.momentTwoDate(
                      new Date(val.load_date_start),
                      new Date(val.load_date_end)
                    )}
                  </div>
                  <div>
                    {"(เวลา " +
                      val.load_time_start.slice(0, 5) +
                      " น. - " +
                      val.load_time_end.slice(0, 5) +
                      " น.)"}
                  </div>
                </div>
                <div
                  className="part5"
                  style={{
                    display: "flex",
                    flex: 1,
                    borderBottom: `2px solid rgba(140, 163, 186, 0.2)`,
                  }}
                >
                  <div
                    style={{
                      flex: 0.5,
                      padding: "5px",
                      borderRight: `2px solid rgba(140, 163, 186, 0.2)`,
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>สถานะงาน</div>
                    <div style={{ fontSize: "24px", fontWeight: 500 }}>
                      {val.work_status.name}
                    </div>
                  </div>
                  <div
                    style={{
                      flex: 0.5,
                      padding: "5px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "24px",
                      color: "#EC1F26",
                    }}
                  >
                    {val.carrier_freight + " บาท"}
                  </div>
                </div>
                <div
                  className="part6"
                  style={{
                    display: "flex",
                    flex: 1,
                    padding: 10,
                  }}
                >
                  <div style={{ flex: 0.5 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        className="buttonLine"
                        onClick={() => window.open("https://lin.ee/G9URMOgj")}
                      >
                        <img src={line_icon} alt="logo line" width={22} />
                        <div>สมัคร/สอบถาม</div>
                      </Button>
                    </div>
                  </div>
                  <div style={{ flex: 0.5, paddingLeft: "5px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        className="blueButton"
                        onClick={() => handleClick(val.id)}
                      >
                        <div>รับงาน (สำหรับสมาชิก)</div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {allWork?.length < totalWork && (
        <div style={{ paddingBottom: "25px" }} onClick={() => loadMore()}>
          ดูงานเพิ่ม
        </div>
      )}
    </div>
  );
};

export default PublicWorkCard;
