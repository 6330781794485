import React from "react";
import "./ProductAndService.css";
import { useNavigate } from "react-router-dom";
const ProductAndService = () => {
  const navigate = useNavigate();
  const list_work = [
    {
      image: require("../../assets/images/productAndService/insurance.png"),
      link: "insurance",
      text: "ประกันภัยสินค้า",
    },
    {
      image: require("../../assets/images/productAndService/fuel.png"),
      link: "fuel",
      text: "บัตรเติมน้ำมันบางจาก",
    },
    {
      image: require("../../assets/images/productAndService/bill.png"),
      link: "bill",
      text: "รับซื้อบิลการค้า",
    },
    // {
    //   image: require("../../assets/images/productAndService/hino.png"),
    //   link: "hino",
    //   text: "ส่วนลดบริการฮีโน่ มิตซุย",
    // },
  ];
  return (
    <div className="container">
      <div className="headTitle">สินค้าและบริการ</div>
      <div>
        {list_work.map((val, i) => {
          return (
            <div
              key={i}
              onClick={() => {
                navigate(val.link);
              }}
            >
              <div style={{ position: "relative" }}>
                <img
                  src={val.image}
                  alt=""
                  width={"100%"}
                  resizemode="contain"
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: 15,
                    width: "100%",
                    zIndex: 100,
                  }}
                >
                  <div className="listTitle">{val.text}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductAndService;
