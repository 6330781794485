import React from "react";
import PlaceIcon from "@mui/icons-material/Place";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./work.css"
const WorkLocation = ({ value }) => {
  return (
    <div>
      {value.map((ele, index) => {
        return (
          <div className="location-route" key={"route" + index}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <PlaceIcon
                sx={{ color: ele.type === 0 ? "#121f43" : "#ff316f" }}
              />
              <MoreVertIcon sx={{ opacity: 0.3 }} />
            </div>
            <div style={{ flex: 1, paddingLeft: 10 }}>
              <div
                style={{
                  color:
                    ele.district !== "" && ele.province !== ""
                      ? "#121f43"
                      : "#ff316f",
                }}
              >
                {ele.place_name !== ""
                  ? ele.place_name
                  : ele.address !== ""
                  ? ele.address
                  : ele.type === 0
                  ? "จุดรับสินค้า?*"
                  : "จุดส่งสินค้า?*"}
              </div>
              <div
                style={{
                  fontSize: 10,
                  opacity: ele.district !== "" && ele.province !== "" ? 1 : 0.3,
                }}
              >
                {ele.subdistrict !== "" &&
                ele.district !== "" &&
                ele.province !== ""
                  ? ele.subdistrict + " " + ele.district + " " + ele.province
                  : "(เลือกสถานที่ที่คุณต้องการมให้" +
                    (ele.type === 0 ? "เข้ารับ" : "จัดส่ง") +
                    "สินค้า)"}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WorkLocation;
