import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProductAndService from "../pages/ProductAndService/ProductAndService";
import Insurance from "../pages/ProductAndService/Insurance";
import Hino from "../pages/ProductAndService/Hino";
import Home from "../pages/Home/Home";
import Bill from "../pages/ProductAndService/Bill";
import ListWork from "../pages/Work/ListWork";
import DetailWork from "../pages/Work/DetailWork";
import WorkPublicDetail from "../pages/WorkPublic/WorkPublicDetail";
import OpenApp from "pages/OpenApp/OpenApp";
import CountWorkByTruck from "pages/WorkPublic/CountWorkByTruck";
import PublicWorkCard from "pages/WorkPublic/PublicWorkCard";
import Fuel from "pages/ProductAndService/Fuel";
import CountWorkRegularByTruck from "pages/Work/CountWorkRegularByTruck";
import CountWorkRegularByZone from "pages/Work/CountWorkRegularByZone";
const routes = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/products_and_services"
            element={<ProductAndService />}
          />
          <Route
            path="/products_and_services/insurance"
            element={<Insurance />}
          />
          <Route path="/products_and_services/fuel" element={<Fuel />} />
          {/* <Route path="/products_and_services/hino" element={<Hino />} /> */}
          <Route path="/products_and_services/bill" element={<Bill />} />
          <Route path="/work-card" element={<CountWorkRegularByTruck />} />
          <Route
            path="/work-card/:truck_id/zone"
            element={<CountWorkRegularByZone />}
          />
          <Route
            path="/work-card/:truck_id/zone/:zone_id"
            element={<ListWork />}
          />
          <Route
            path="/work-card/:truck_id/zone/:zone_id/detail/:work_id"
            element={<DetailWork />}
          />
          {/* <Route path="/public/tracking/:id_secret" element={<WorkPublicDetail />} /> */}
          <Route path="/open-app/:wid" element={<OpenApp />} />
          <Route path="/public/work" element={<CountWorkByTruck />} />
          <Route
            path="/public/work/card/:truck_id"
            element={<PublicWorkCard />}
          />
        </Routes>
      </Router>
    </div>
  );
};

export default routes;
