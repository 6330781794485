import helper from "utils/helper";

export const getZone = async (params = {}) => {
    try {
      let api = await helper.setAPI();
      return await api.get(`api/master/zone`, {
        params: params,
      });
    } catch (error) {
      console.log(`error: `, error);
      throw error;
    }
  };