import React from 'react'
import "./ProductAndService.css"
import BackButton from '../../components/button/BackButton'
import ButtonCustom from '../../components/button/ButtonCustom'
const Hino = () => {
  return (
    <div className="container">
    <BackButton />
    <div className="pageTitle">ส่วนลดบริการฮีโน่ มิตซุย</div>
    <div style={{padding:"10px"}}>
      <img
        src={require("../../assets/images/productAndService/hino_detail.png")}
        alt=""
        width={"100%"}
        resizemode="contain"
      />
    </div>
    <div style={{width:'80%'}}>
      <ButtonCustom
        name="ลงทะเบียนรับสิทธิ์"
        onClick={() => {
          window.open(
            "https://forms.gle/Ber4JrcduDdjsSay8"
          );
        }}
        fullWidth={true}
      />
    </div>
  </div>
  )
}

export default Hino