import helper from "utils/helper";

export const getTruckType = async (params = {}) => {
  try {
    let api = await helper.setAPI();
    return await api.get(`api/master/truck-type`, {
      params: params,
    });
  } catch (error) {
    console.log(`error: `, error);
    throw error;
  }
};
