// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-route {
  padding: 10px 0px 0px 10px;
  display: flex;
  cursor: pointer;
}
.container-work-comp {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px 0px 10px 0px;
  cursor: pointer;
}
.section-image {
  flex: 0.5 1;
  display: flex;
  justify-content: center;
  padding: 10px 0px 10px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/work/work.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,aAAa;EACb,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,0BAA0B;EAC1B,eAAe;AACjB;AACA;EACE,WAAS;EACT,aAAa;EACb,uBAAuB;EACvB,0BAA0B;AAC5B","sourcesContent":[".location-route {\n  padding: 10px 0px 0px 10px;\n  display: flex;\n  cursor: pointer;\n}\n.container-work-comp {\n  display: flex;\n  align-items: center;\n  text-align: center;\n  padding: 10px 0px 10px 0px;\n  cursor: pointer;\n}\n.section-image {\n  flex: 0.5;\n  display: flex;\n  justify-content: center;\n  padding: 10px 0px 10px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
