import {
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
  } from "@mui/material";
  import React, { useState } from "react";
  import "./inputDisable.css";
  import { UploadFile } from "@mui/icons-material";
  import ModalWork from "components/work/modalWork";
  
  export default function InputUpload({
    error,
    value,
    onChange,
    label,
    id,
    name,
    subFirst,
    subSec,
    accept = "image/*",
    type = "text",
    icon = <UploadFile />,
    error_label,
    maxLength,
    disable = false,
  }) {
    const [showImage, setShowImage] = useState(null);
    const addImage = () => {
      document.getElementById(id + "upload_img").click();
    };
    const onFileChange = (e) => {
      // console.log(e, index);
      const file = e.target.files[0];
      if (file) {
        onChange({
          src: URL.createObjectURL(file),
          file,
        });
      }
      // setImg(tmp);
      // console.log("tmp", tmp);
  
      // let tmp = [...value];
      // tmp.push({
      //   src: URL.createObjectURL(file),
      //   file,
      // });
      //
      // console.log(this.state.img_product)
    };
    return (
      <div
        className={
          "inputNormal " +
          (error ? "errorLabel" : "") +
          (disable ? " inputDisable" : "")
        }
      >
        <FormControl variant="standard" autoFocus fullWidth margin="none">
          <InputLabel color={error ? "error" : "primary"}>{label}</InputLabel>
          <Input
            type={type}
            error={error}
            id={id}
            label={label}
            value={disable ? value : value?.file?.name}
            //   name={name}
            onClick={() => {
              if (disable) setShowImage(value);
              else addImage();
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton>{icon}</IconButton>
              </InputAdornment>
            }
            sx={{
              backgroundColor: disable ? "rgb(140,163,186,0.2)" : "",
            }}
            // disabled={disable}
          />
          {subFirst || subSec || error_label ? (
            <div className="inputNormalSub">
              <div>{subFirst}</div>
              <div>{subSec}</div>
              <div>{error && error_label ? error_label : ""}</div>
            </div>
          ) : null}
  
          <input
            type={"file"}
            id={id + "upload_img"}
            accept={accept}
            onChange={(val) => {
              onFileChange(val);
            }}
            style={{ display: "none" }}
          />
        </FormControl>
        <ModalWork
          open={showImage ? true : false}
          close={() => setShowImage(null)}
          enableClose
        >
          <img
            src={showImage}
            alt="img"
            style={{
              width: "100%",
              height: "auto",
              padding: "0px 20px 0px 20px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(
                showImage,
                "_blank" // <- This is what makes it open in a new window.
              );
            }}
          />
        </ModalWork>
      </div>
    );
  }
  