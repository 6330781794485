import React from "react";
import helper from "utils/helper";
import "./work.css"
const WorkTruck = ({value}) => {
  return (
    <div>
      <div
className="container-work-comp"
      >
        <div className="section-image"
        >
          <img
            src={
              value
                ? helper.addhtpps(value.image)
                : require("assets/images/work/truck.png")
            }
            style={{ height: 40, objectFit: "contain", width: "auto" }}
            alt={"truck"}
          />
        </div>
        <div style={{ flex: 1, textAlignLast: "start" }}>
          <div style={{ fontWeight: 500 }}>{value?.name ?? "ประเภทรถ"}</div>
          <div style={{ fontWeight: 300, fontSize: 14 }}>
            {value
              ? value.width +
                " x " +
                value.length +
                " x " +
                value.height +
                " ม. " +
                value.weight +
                " กก."
              : "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkTruck;
