import React from "react";
import "./work.css";
const WorkCarrier = (props) => {
  const { carrier, onChange = () => {}, driver, truck } = props;
  return (
    <div>
      <div className="container-work-comp">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "5px 10px 5px 10px",
          }}
        >
          <img
            src={require("assets/images/work/carrier.png")}
            height={40}
            alt={"carrier"}
          />
        </div>
        <div style={{ flex: 1, textAlignLast: "start" }}>
          <div
            style={{
              fontWeight: 500,
              opacity: 1,
            }}
          >
            {"ผู้ขนส่งที่รับงาน"}
          </div>
          <div
            style={{
              fontSize: 12,
              opacity: 1,
            }}
          >
            ผู้ขนส่ง: {carrier?.company_name ?? ""}{" "}
            {carrier?.user_info?.phone ?? ""}
            <br />
            คนขับ: {driver ? driver.firstname + " " + driver.lastname : ""}{" "}
            {driver?.phone ?? ""}
            <br />
            ทะเบียนรถ:{" "}
            {truck
              ? truck.license_plate_head +
                (truck.license_plate_tail && truck.license_plate_tail !== ""
                  ? " " + truck.license_plate_tail
                  : "")
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkCarrier;
