import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "components/loading/Loading";
import { getTruckType as getAllTruckType } from "api/masterData/truckType";
import { getAllWorkPublic } from "api/public/workPublic";
import API from "../../api/work/work";
const api = API.create();
const CountWorkRegularByTruck = ({}) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loading, setLoading] = useState(false);
  const [truckMaster, setTruckMaster] = useState([]);
  const [allWork, setAllWork] = useState(null);

  useEffect(() => {
    getTruckMaster();
    getAllWork();
  }, []); 

  useEffect(() => {
    console.log("truckMaster", truckMaster);
    console.log("allWork", allWork);
  }, [allWork, truckMaster]);
  const getTruckMaster = async () => {
    const param = {
      sort_field: ["order_number"],
      sort_order: [1],
    };
    await getAllTruckType(param)
      .then((res) => {
        console.log("all truck type", res);
        if (res.data.code === 1000) {
          setTruckMaster(res.data.data.results);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getAllWork = async () => {
    console.log("do count data");
    await api.getAllWorkRegular().then((res) => {
        if(res.data.code === 1000){
            setAllWork(res.data.results.data)
        }
      })
      .catch((error) => console.log("countres", error));
  };

  return (
    <div className={!isMobile ? "containerForWebPW" : "containerPW"}>
      {loading && <Loading />}
      <div style={{ fontSize: 24, fontWeight: 500, paddingTop: "30px" }}>
        ประเภทรถ
      </div>
      <div style={{ paddingTop: 20, width: "100%" }}>
        {truckMaster?.map((val, i) => {
          return allWork?.filter((ele) => ele.truck_type_id === val.id).length >
            0 ? (
            <div style={{ padding: "5px 20px" }}>
              <div
                className="cardTruck"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/work-card/${val?.id}/zone`)}
              >
                <div className="section1">
                  <div style={{ fontSize: "24px" }}>
                    {
                      allWork?.filter((ele) => ele.truck_type_id === val.id)
                        .length
                    }
                  </div>
                  <div style={{ fontSize: "12px" }}>งาน</div>
                </div>
                <div className="section2">
                  <img
                    src={`http://${val?.image}`}
                    alt=""
                    width={215}
                    height={50}
                    resizeMode="contain"
                  />
                  <div>{val?.name}</div>
                </div>
              </div>
            </div>
          ) : (
            ""
          );
        })}
      </div>
    </div>
  );
};

export default CountWorkRegularByTruck;
