import React from "react";

const Line = ({ width, opacity }) => {
  return (
    <div
      style={{
        // borderBottomStyle: "solid",
        // borderBottomWidth: width ?? 1,
        height: width ?? 1,
        backgroundColor: "#121f43",
        opacity: opacity ?? 0.2,
      }}
    ></div>
  );
};

export default Line;
