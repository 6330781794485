// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backBttn {
  cursor: pointer;
  padding: 10px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: row;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/button/button.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,2BAA2B;EAC3B,WAAW;EACX,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".backBttn {\n  cursor: pointer;\n  padding: 10px;\n  font-size: 20px;\n  font-weight: 500;\n  display: flex;\n  justify-content: flex-start;\n  width: 100%;\n  flex-direction: row;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
