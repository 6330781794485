import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "components/loading/Loading";
import { getTruckType as getAllTruckType } from "api/masterData/truckType";
import { getAllWorkPublic } from "api/public/workPublic";
import backArrow from "assets/images/workCard/back-arrow.svg";
import API from "../../api/work/work";
import { getZone } from "api/masterData/zone";
const api = API.create();
const CountWorkRegularByZone = ({}) => {
  const navigate = useNavigate();
  const { truck_id } = useParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loading, setLoading] = useState(false);
  const [zoneMaster, setZoneMaster] = useState([]);
  const [allWork, setAllWork] = useState(null);

  useEffect(() => {
    getZoneMaster();
    getAllWork();
  }, []);

  useEffect(() => {
    console.log("zoneMaster", zoneMaster);
    console.log("allWork", allWork);
  }, [allWork, zoneMaster]);
  const getZoneMaster = async () => {
    const param = {
      sort_field: ["order_number"],
      sort_order: [1],
    };
    await getZone(param)
      .then((res) => {
        console.log("all truck type", res);
        if (res.data.code === 1000) {
          setZoneMaster(res.data.data.results);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getAllWork = async () => {
    console.log("do count data");
    const param = {
      truck_type_id: truck_id,
    };
    await api
      .getAllWorkRegular(param)
      .then((res) => {
        if (res.data.code === 1000) {
          setAllWork(res.data.results.data);
        }
      })
      .catch((error) => console.log("countres", error));
  };
  // let zoneWorkCount = {};
  // useEffect(() => {
  //   zoneMaster?.forEach((zone) => {
  //     zoneWorkCount[zone.name] = 0;
  //   });

  //   allWork?.forEach((item) => {
  //     item.workRegularZone.forEach((zoneInfo) => {
  //       const zoneName = zoneMaster.find(zone => zone.id === zoneInfo.zone).name;
  //       zoneWorkCount[zoneName]++;
  //     });
  //   });
  // }, [zoneMaster, allWork]);
  const [zoneWorkCount, setZoneWorkCount] = useState([]);

  // Function to count the number of work items for each zone
  useEffect(() => {
    const newZoneWorkCount = [];
    zoneMaster.forEach((zone) => {
      const count = allWork?.reduce((acc, item) => {
        return (
          acc +
          item.workRegularZone.filter((zoneInfo) => zoneInfo.zone === zone.id)
            .length
        );
      }, 0);
      if (count > 0) {
        newZoneWorkCount.push({ id: zone.id, name: zone.name, count });
      }
    });
    setZoneWorkCount(newZoneWorkCount);
  }, [zoneMaster, allWork]);

  useEffect(() => {
    console.log("zoneWorkCount", zoneWorkCount);
  }, [zoneWorkCount]);

  return (
    <div className={!isMobile ? "containerForWebPW" : "containerPW"}>
      <div
        style={{
          paddingTop: "20px",
          paddingLeft: "20px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <div onClick={() => navigate(-1)}>
          <img src={backArrow} width={25} />
        </div>
      </div>
      {loading && <Loading />}
      <div style={{ fontSize: 24, fontWeight: 500}}>
        ภูมิภาค
      </div>
      <div style={{ paddingTop: 20, width: "100%" }}>
        <div>
          {zoneWorkCount.map((zone) => (
            <div style={{ padding: "5px 20px" }}>
              <div
                className="cardTruck"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`/work-card/${truck_id}/zone/${zone.id}`)
                }
              >
                <div className="section1">
                  <div style={{ fontSize: "24px" }}>{zone.count}</div>
                  <div style={{ fontSize: "12px" }}>งาน</div>
                </div>
                <div className="section2">
                  <div>{zone.name}</div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* {zoneMaster?.map((val, i) => {
          return allWork?.filter((ele) =>
            ele.workRegularZone.map((vall) => {
              vall.zone === val.id;
            }).length > 0 ? (
              <div style={{ padding: "5px 20px" }}>
                {val.id}
                <div
                  className="cardTruck"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/work-card/${val?.id}/zone`)}
                >
                  <div className="section1">
                    <div style={{ fontSize: "24px" }}>
                      {
                        ele?.workRegularZone?.filter((el) => el.zone === val.id)
                          .length
                      }
                    </div>
                    <div style={{ fontSize: "12px" }}>งาน</div>
                  </div>
                  <div className="section2">
                    <div>{val?.name}</div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          );
        })} */}
      </div>
    </div>
  );
};

export default CountWorkRegularByZone;
