import React from "react";
import BackButton from "../../components/button/BackButton";
import "./ProductAndService.css";
import ButtonCustom from "../../components/button/ButtonCustom";
const Insurance = () => {
  return (
    <div className="container">
      <BackButton />
      <div className="pageTitle">ประกันภัยสินค้ารายปี</div>
      <div className="ph10 f16">ตารางค่าเบี้ยประกันฯ</div>
      <div style={{padding:"10px"}}>
        <img
          src={require("../../assets/images/productAndService/insurance_detail.png")}
          alt=""
          width={"100%"}
          resizemode="contain"
        />
      </div>
      <div
        className="ph10 textUnderline f16"
        onClick={() => {
          window.open("https://minio.wemove.co.th/bucket-test/policy.pdf");
        }}
      >
        อ่านเงื่อนไขกรมธรรม์
      </div>
      <div style={{width:'80%'}}>
        <ButtonCustom
          name="ทักไลน์ติดต่อซื้อประกันภัยสินค้ารายปี"
          onClick={() => {
            window.open(
              "https://page.line.me/smv4552s?oat_content=url&openQrModal=true"
            );
          }}
          fullWidth={true}
        />
      </div>
    </div>
  );
};

export default Insurance;
