// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headWorkCode{
  padding: 10px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}
.headSwipeWork {
    padding: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }`, "",{"version":3,"sources":["webpack://./src/pages/WorkPublic/workPublic.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;AACA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,uBAAuB;EACzB","sourcesContent":[".headWorkCode{\n  padding: 10px;\n  text-align: center;\n  font-size: 24px;\n  font-weight: 500;\n}\n.headSwipeWork {\n    padding: 5px;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    justify-content: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
