import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import backArrow from "../../assets/images/workCard/back-arrow.svg";
import logoLine from "../../assets/images/workCard/logoLine.webp";
import tel from "../../assets/images/workCard/telephone-call.png";
import { useParams, useNavigate } from "react-router-dom";
import { work } from "../../assets/mock/mockWork";
import API from "../../api/work/work";
const api = API.create();
const DetailWork = () => {
  const { work_id } = useParams();
  const navigate = useNavigate();
  const [detailData, setDetailData] = useState();
  useEffect(() => {
    // console.log("query");
    // const data = work?.filter((val) => val.id == work_id);
    // setDetailData(data[0].detail);
    getWorkById(work_id);
  }, []);

  const getWorkById = async (id) => {
    const response = await api.getWorkRegularById(id);
    console.log("response", response.data.data);
    setDetailData(response.data.data);
  };
  function openPhoneNumber(phoneNumber) {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      var formattedNumber = phoneNumber.replace(/\D/g, "");

      window.location.href = "tel:" + formattedNumber;
    } else {
      console.log("This feature is only available on mobile devices.");
    }
  }
  return (
    <div className="container alStart">
      <div>
        <div style={{ padding: "20px" }}>
          <div onClick={() => navigate(-1)}>
            <img src={backArrow} width={25} />
          </div>
          <div style={{ padding: "10px 30px 30px 30px" }}>
            <div
              style={{
                position: "sticky",
                top: 0,
                left: 0,
                backgroundColor: "white",
                width: "105%",
                zIndex: 20,
              }}
            >
              <div
                style={{ fontWeight: 500, fontSize: "30px", color: "#00008B" }}
              >
                รายละเอียด
              </div>
            </div>

            <div>
              <br />
              <div style={{ fontWeight: 500, fontSize: "20px" }}>
                {detailData?.work_regular_code}
              </div>
            </div>

            {detailData?.profile_info ? (
              <div>
                <br />
                <div style={{ fontSize: "16px", color: "#00008B" }}>
                  ชื่อโปรไฟล์
                </div>
                <div style={{ fontWeight: 300, fontSize: "14px" }}>
                  {detailData?.profile_info.company_name}
                </div>
              </div>
            ) : (
              ""
            )}
            {detailData?.work_regular_name ? (
              <div>
                <div style={{ fontSize: "16px", color: "#00008B" }}>
                  ชื่อประกาศ
                </div>
                <div style={{ fontWeight: 300, fontSize: "14px" }}>
                  {detailData?.work_regular_name}
                </div>
              </div>
            ) : (
              ""
            )}
            {detailData?.product_type ? (
              <div>
                <div style={{ fontSize: "16px", color: "#00008B" }}>
                  ประเภทสินค้า
                </div>
                <div style={{ fontWeight: 300, fontSize: "14px" }}>
                  {detailData?.product_type.name}
                </div>
              </div>
            ) : (
              ""
            )}
            {detailData?.product_name ? (
              <div>
                <div style={{ fontSize: "16px", color: "#00008B" }}>
                  ชนิดสินค้า
                </div>
                <div style={{ fontWeight: 300, fontSize: "14px" }}>
                  {detailData?.product_name}
                </div>
              </div>
            ) : (
              ""
            )}
            {detailData?.truck_type ? (
              <div>
                <div style={{ fontSize: "16px", color: "#00008B" }}>
                  ประเภทรถ
                </div>
                <div style={{ fontWeight: 300, fontSize: "14px" }}>
                  {detailData?.truck_type.name}
                </div>
              </div>
            ) : (
              ""
            )}

            <div>
              <div style={{ fontSize: "16px", color: "#00008B" }}>
                บริการเสริม
              </div>
              {detailData?.has_document_return && (
                <div style={{ fontWeight: 300, fontSize: "14px" }}>
                  ต้องการส่งเอกสารนำกลับ
                </div>
              )}
              {detailData?.has_labor && detailData.labor_load_amount !== 0 && (
                <div style={{ fontWeight: 300, fontSize: "14px" }}>
                  ต้องการคนงานยกสินค้าขึ้น {detailData.labor_load_amount} คน
                </div>
              )}
              {detailData?.has_labor &&
                detailData.labor_unload_amount !== 0 && (
                  <div style={{ fontWeight: 300, fontSize: "14px" }}>
                    ต้องการคนงานยกสินค้าลง {detailData.labor_unload_amount} คน
                  </div>
                )}
            </div>

            <div>
              <div style={{ fontSize: "16px", color: "#00008B" }}>ต้นทาง</div>
              {detailData?.workRegularOrigin.map((val, i) => {
                return (
                  <div style={{ fontWeight: 300, fontSize: "14px" }}>
                    {i + 1}. {val?.origin_province.name}
                  </div>
                );
              })}
            </div>
            <div>
              <div style={{ fontSize: "16px", color: "#00008B" }}>ปลายทาง</div>
              {detailData?.workRegularDestination.map((val, i) => {
                return (
                  <div style={{ fontWeight: 300, fontSize: "14px" }}>
                    {i + 1}. {val?.destination_province.name}
                  </div>
                );
              })}
            </div>
            <div>
              <div style={{ fontSize: "16px", color: "#00008B" }}>
                รายละเอียดเพิ่มเติม
              </div>
              <pre
                style={{
                  fontWeight: 300,
                  fontFamily: "Kanit",
                  fontSize: "14px",
                  maxWidth: "100%",
                  whiteSpace: "pre-wrap",
                }}
              >
                {detailData?.more_details}
              </pre>
            </div>
            <br />
            <div>
              <div style={{ fontSize: "16px", color: "#00008B" }}>
                โทรติดต่อเจ้าหน้าที่
              </div>
              <button
                onClick={() => openPhoneNumber("0618505665")}
                style={{
                  fontWeight: 300,
                  fontSize: "14px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderColor: "#00CCCC",
                  color: "#00CCCC",
                  padding: "6px 10px",
                  borderRadius: "6px",
                  backgroundColor: "white",
                }}
              >
                <div>
                  <img src={tel} alt="logo line" width={34} />
                </div>
                <div style={{ paddingLeft: "10px" }}>0618505665</div>
              </button>
            </div>

            <br />
            <div>
              <div style={{ fontSize: "16px", color: "#00008B" }}>
                ไลน์ติดต่อเจ้าหน้าที่
              </div>
              <div style={{ fontWeight: 300, fontSize: "14px" }}>
                <Button
                  variant="contained"
                  onClick={() => window.open("https://lin.ee/G9URMOgj")}
                  style={{ backgroundColor: "#06c755", borderRadius: "10px" }}
                >
                  <img src={logoLine} alt="logo line" width={22} />
                  <div style={{ fontSize: "12px" }}>สมัคร/สอบถาม</div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailWork;
