
import { longdo } from "components/map/LongdoMap";
const HelperMap = {
  async renderMaker(detail) {
    let data = detail;
    let html = "";
    let location = {
      lat: data.lat,
      lon: data.long,
    };
    if (data.type === "pickup") {
      html =
        '<div class="d-flex flex-row"><img src="' +
        require("assets/images/work/pick.png") +
        '" style="width: 30px;margin-top: 0px;"/></div>"';
    } else if (data.type === "send") {
      html =
        '<div class="d-flex flex-row"><img src="' +
        require("assets/images/work/send.png") +
        '" style="width: 30px;margin-top: 0px;"/>"';
    } else if (data.type === "driver") {
      html =
        '<div class="d-flex flex-row"><img src="' +
        require("assets/images/work/truckpin.png") +
        '" style="width: 30px;margin-top: 0px;"/>"';
    } else {
      html =
        '<div class="d-flex flex-row"><img src="' +
        require("assets/images/work/abnormal.png") +
        '" style="width: 30px;margin-top: 0px;"/>"';
    }
    let mark = await new window.longdo.Marker(location, {
      title:
        data.type === "pickup"
          ? "จุดรับ"
          : data.type === "send"
          ? "จุดส่ง"
          : "แจ้งปัญหา",
      draggable: false,
      clickable: false,
      weight: window.longdo.OverlayWeight.Top,
      icon: {
        html,
      },
    });
    return mark;
  },

  getBound(data, boundData) {
    // console.log(boundData);
    // return;
    let bound = boundData;
    if (bound.maxLon === null || bound.maxLon < parseFloat(data.long)) {
      bound.maxLon = parseFloat(data.long);
    }
    if (bound.maxLat === null || bound.maxLat < parseFloat(data.lat)) {
      bound.maxLat = parseFloat(data.lat);
    }
    if (bound.minLon === null || bound.minLon > parseFloat(data.long)) {
      bound.minLon = parseFloat(data.long);
    }
    if (bound.minLat === null || bound.minLat > parseFloat(data.lat)) {
      bound.minLat = parseFloat(data.lat);
    }
    return bound;
  },

  async getMarkerFromStatus(status) {
    console.log("status", status);
    let mark = [];
    for (const { ele, index } of status.map((ele, index) => ({
      ele,
      index,
    }))) {
      if (ele.workTrackingDetail) {
        console.log(ele);
        if (
          ele.workTrackingDetail.epod_lat &&
          ele.workTrackingDetail.epod_long
        ) {
          // this
          let marker = await this.renderMaker({
            lat: ele.workTrackingDetail.epod_lat,
            long: ele.workTrackingDetail.epod_long,
            type: ele.work_status === 8 ? "pickup" : "send",
          });
          // console.log("marker", marker);
          mark.push(marker);
        }
      }
      if (ele.workAbnormal) {
        if (ele.workAbnormal.abnormal_lat && ele.workAbnormal.abnormal_long) {
          // this
          let marker = await this.renderMaker({
            lat: ele.workAbnormal.abnormal_lat,
            long: ele.workAbnormal.abnormal_long,
            type: "abnormal",
          });
          // console.log("marker", marker);
          mark.push(marker);
        }
      }
    }

    return mark;
  },
};
export default HelperMap;
