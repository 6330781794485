import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import "./button.css";
const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(-1)}
      className="backBttn"
    >
      <ArrowBackRoundedIcon sx={{ fontSize: 40 }}/>
    </div>
  );
};

export default BackButton;
