// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headBar {
  background-color: #c72026;
  height: 55px;
  width: 100%;
  border-radius: 15px 15px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.headTitileCard {
  font-size: 22px;
  font-weight: 500;
  color: white;
}

.rightTitleCard {
  color: white;
  display: flex;
  font-size: 18px;
  font-weight: 300;
  align-items: flex-end;
}

.bottomCard {
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 0px 0px 15px 15px;
  flex-direction: column;
  background-color: white;
}

.section {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-bottom: 1px solid lightgray;
}

.cardContainer {
  display: flex;
  width: 340px;
  flex-direction: column;
  position: relative;
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/card/card.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,4BAA4B;EAC5B,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX;uCACqC;EACrC,gCAAgC;EAChC,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".headBar {\n  background-color: #c72026;\n  height: 55px;\n  width: 100%;\n  border-radius: 15px 15px 0 0;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px 20px;\n}\n\n.headTitileCard {\n  font-size: 22px;\n  font-weight: 500;\n  color: white;\n}\n\n.rightTitleCard {\n  color: white;\n  display: flex;\n  font-size: 18px;\n  font-weight: 300;\n  align-items: flex-end;\n}\n\n.bottomCard {\n  width: 100%;\n  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,\n    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;\n  border-radius: 0px 0px 15px 15px;\n  flex-direction: column;\n  background-color: white;\n}\n\n.section {\n  display: flex;\n  flex-direction: row;\n  padding: 10px;\n  border-bottom: 1px solid lightgray;\n}\n\n.cardContainer {\n  display: flex;\n  width: 340px;\n  flex-direction: column;\n  position: relative;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
