import React from 'react'
import "./work.css";
const WorkRemark = ({value}) => {
  return (
    <div>      <div
className='container-work-comp'
  >
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "5px 10px 5px 10px",
      }}
    >
      <img
        src={
          value && value.image
            ? value.image
            : require("assets/images/work/remark.png")
        }
        height={40}
        alt={"bill"}
      />
    </div>
    <div style={{ flex: 1, textAlignLast: "start" }}>
      <div style={{ fontWeight: 500, opacity: value ? 1 : 0.3 }}>
        หมายเหตุ
      </div>
      <div style={{ fontSize: 13, opacity: value ? 1 : 0.3 }}>
        {value ? value : "เพื่ออธิบายให้คนขับเข้าใจมากยิ่งขึ้น (ไม่บังคับ)"}
      </div>
    </div>
  </div></div>
  )
}

export default WorkRemark