import React, { useState, useEffect } from "react";

const Home = () => {
  const [locationLatLon, setLocationLatLon] = useState({});
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(async function (position) {
      setLocationLatLon({
        lat: position.coords.latitude,
        lon: position.coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    console.log("locationLatLon", locationLatLon);
  }, [locationLatLon]);
  return (
    <div className="container">
      <div>{locationLatLon.lat}</div>
      <div>{locationLatLon.lon}</div>
    </div>
  );
};

export default Home;
